/**
 * Filtert Elemente, die ein Attribut 'data-xxx' haben, wenn ein Button mit Attribut 'filter-xxx'
 * aktiv ist. Aktive Filter werden als URL-Parameter "persistiert".
 */
class ArtistListFilter {
    constructor(filterAttributes) {
        this.filterAttributes = new Set(filterAttributes);
        this.filterValues = new Map();

        this.parseUrl();
        this.addFilterButtonEventListeners();
    }

    logState() {
        this.log("filterAttributes: ", this.filterAttributes);
        this.log("filterValues: ", this.filterValues);
    }

    log(...args) {
        //console.log(...args);
    }

    addFilterButtonEventListeners() {
        this.filterAttributes.forEach(name => {
            const filterName = "filter-" + name;

            const btns = document.querySelectorAll("[" + filterName + "]");
            this.log("Enable toggling on ", btns);
            btns.forEach(btn => {
                btn.addEventListener('click', () => {
                    const criteria = btn.attributes[filterName].value;
                    this.toggleFilterEnabled(name, criteria);
                });
            });
        });
    }

    isFilterEnabled(filter, value) {
        return this.filterValues[filter].has(value);
    }

    showItemForFilter(filter, value) {
        // Sonderfall: wenn kein Filter aktiv, alle Einträge anzeigen
        return this.filterValues[filter].size == 0 || this.isFilterEnabled(filter, value)
    }

    setFilterEnabled(filter, value, enabled) {
        if (enabled) {
            this.filterValues[filter].add(value);
        } else {
            this.filterValues[filter].delete(value);
        }

        this.updateUrl()
        this.updateDocumentElements();
        this.logState();
    }

    toggleFilterEnabled(filterName, value) {
        this.log("toggleFilterEnabled", filterName, value);
        this.setFilterEnabled(filterName, value, !this.isFilterEnabled(filterName, value));
    }

    parseUrl() {
        const url = new URL(window.location);
        this.filterAttributes.forEach(attr => {
            const value = url.searchParams.get("filter-" + attr);
            if (value) {
                this.filterValues[attr] = new Set(value.split("-"));
            } else {
                this.filterValues[attr] = new Set();
            }
        });

        this.logState();
        this.updateDocumentElements();
    }

    updateUrl() {
        const url = new URL(window.location);
        this.filterAttributes.forEach(attr => {
            const values = this.filterValues[attr];
            const filterAttrName = "filter-" + attr;
            if (values.size == 0) {
                url.searchParams.delete(filterAttrName);
            } else {
                url.searchParams.set(filterAttrName, Array.from(values).sort().join("-"));
            }
        });
        window.history.replaceState({}, '', url);
    }

    updateDocumentElements() {
        // Filter-Buttons aktualisieren
        this.filterAttributes.forEach(filterName => {
            const btns = document.querySelectorAll('[filter-' + filterName + ']')
            btns.forEach(btn => {
                const value = btn.attributes['filter-' + filterName].value;
                const active = this.isFilterEnabled(filterName, value);
                if (active) {
                    btn.classList.add('active');
                } else {
                    btn.classList.remove('active');
                }
            });
        });

        // data-Elemente ein-/ausblenden
        // 1) Alle Elemente die irgendein 'data-[filter]' Attribut haben finden
        const allFilteredRowsSelector = Array.from(this.filterAttributes).map(e => {
            return '[data-' + e + ']'
        }).join(", ")
        const rows = document.querySelectorAll(allFilteredRowsSelector)

        // 2) Sichtbarkeit setzen
        this.log("updating " + rows.length + " rows");
        rows.forEach(row => {
            let visible = true
            // Show element except one filter says no
            this.filterAttributes.forEach(attr => {
                const value = row.attributes['data-' + attr].value;
                visible = visible && this.showItemForFilter(attr, value);
            })
            if (visible) {
                row.classList.remove('filtered-invisible');
            } else {
                row.classList.add('filtered-invisible');
            }
        });
    }
}

document.addEventListener("DOMContentLoaded", function () {
    const filter = new ArtistListFilter(["category", "gender"]);
});
