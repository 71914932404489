document.addEventListener("DOMContentLoaded", function() {

    // Navigation
    document.querySelector('#btn_show_menu').addEventListener('click', () => {
        document.body.classList.add('nav-active');
    });
    document.querySelector('#btn_close_menu').addEventListener('click', () => {
        document.body.classList.remove('nav-active');
    });

    // Klipp-Klapp beim Veranstaltungskalender - wird per Ajax nachgeladen (Aufruf über globale Funktion
    // gestaltet sich da einfacher)
    window.togglePreview = function(element) {
        element.parentElement.classList.toggle('active');
        document.querySelector('.eventlist').swiper.updateAutoHeight();
    }
});
