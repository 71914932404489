import Swiper, {Pagination, Keyboard, Manipulation} from 'swiper';
import Rails from '@rails/ujs';

document.addEventListener("DOMContentLoaded", function () {

    new Swiper('.eventlist', {
        autoHeight: true,
        modules: [Pagination, Keyboard, Manipulation],
        on: {
            afterInit: function () {
                if (window.sliderPreloadContent != null) {
                    window.sliderPreloadContent();
                    window.sliderPreloadContent = null;
                }
            },
            slideChange: function () {
                document.querySelector('.header .date').innerHTML = this.slides[this.activeIndex].attributes["formatted_date"].value;

                // sliderPreloadContent löst durch das hinzufügen von Slides diese Events aus
                // dadurch kein Nachladen / aktualisieren der URL
                if (window.sliderPreloadContent == null) {
                    let offset = null;
                    if (this.activeIndex == 0) {
                        offset = -1;
                    } else if (this.activeIndex == (this.slides.length - 1)) {
                        offset = 1;
                    }

                    if (offset != null) {
                        const date = this.slides[this.activeIndex].attributes["date"].value;
                        Rails.ajax({
                            type: "GET",
                            url: "/event_slider",
                            data: new URLSearchParams({
                                date: date,
                                offset: offset
                            }).toString()
                        });
                    }

                    const url = new URL(window.location);
                    url.searchParams.set('date', this.slides[this.activeIndex].attributes["date"].value);
                    window.history.replaceState({}, '', url);
                }

            }
        },
        keyboard: {
            enabled: true,
            onlyInViewport: false,
        },
    });

    new Swiper('.attachment-gallery', {
        autoHeight: true,
        modules: [Pagination],
        pagination: {
            el: ".swiper-pagination",
        },
    });

});
