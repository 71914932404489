// When the user scrolls down 20px from the top of the document, show the button
document.addEventListener("DOMContentLoaded", function() {
  if(document.getElementsByClassName("scroll_top").length > 0)
    window.onscroll = function() { scrollFunction() };
});

function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    document.getElementsByClassName("scroll_top")[0].style.display = "block";
  } else {
    document.getElementsByClassName("scroll_top")[0].style.display = "none";
  }
}

// When the user clicks on the button, scroll to the top of the document
window.topFunction = function() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}
