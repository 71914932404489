// Alle serviceWorker der Seite entfernen
// Wir hatten mal einen serviceworker.js ohne davon Gebrauch zu machen => wurde entfernt Oktober 2021.
// Der Ordnung halber werden die Service-Worker mit diesem Skript soweit bei Usern schon vorhanden
// wieder deregistriert. Dieses Skript sollte nach einigen Monaten entfernt werden.
if (navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
            registration.unregister()
        }
    });
}