import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';

Rails.start();
ActiveStorage.start();

import '../application/javascripts/scroll_top'
import '../application/javascripts/swiper'
import '../application/javascripts/nav_toggle'
import '../application/javascripts/artists_filter'
import '../application/javascripts/remove_serviceworkers'

